import { useEffect, useState } from "react";
import { recordExchange, recordWithdraw, recordShared, recordOutput } from "../api/httpApiList";
import * as dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const RecordTab = () => {
    const { t } = useTranslation();
    const [tabactive, setTabActive] = useState("tExchange");
    const tabClick = (e) => {
        setTabActive(e.target.id)
    }

    const [recordList, setRecordList] = useState([]);

    // 时间戳转换
    const getTime = (time) => {
        return dayjs(time).format("YYYY-MM-DD HH:mm:ss")
    }

    useEffect(() => {
        if (tabactive == 'tExchange') {
            recordExchange({ page: 1 }).then((res) => {
                if (res.ok) {
                    setRecordList(res.data.record);
                }
            })
        } else if (tabactive == 'tWithdraw') {
            recordWithdraw({ page: 1 }).then((res) => {
                if (res.ok) {
                    setRecordList(res.data.record);
                }
            })
        } else if (tabactive == 'tShared') {

            recordShared({ page: 1 }).then((res) => {
                if (res.ok) {
                    setRecordList(res.data.record);
                }
            })
        } else if (tabactive == 'tOutput') {

            recordOutput({ page: 1 }).then((res) => {
                if (res.ok) {
                    setRecordList(res.data.record);
                }
            })
        }

    }, [tabactive])

    function hideEthAddress(address) {

        if (!/^0x[0-9a-fA-F]{40}$/.test(address)) {
            return 'Invalid Ethereum address';
          }
        
        // 将地址部分隐藏
        const hiddenAddress = `${address.substring(0, 6)}*****${address.substring(38)}`;
        return hiddenAddress;
    }
    return (
        <div className="record-tab-group">
            <ul className="record-tab-box">
                <li id="tExchange" onClick={tabClick} className={tabactive === 'tExchange' ? "active" : ''}>{t("兑换")}</li>
                <li id="tWithdraw" onClick={tabClick} className={tabactive === 'tWithdraw' ? "active" : ''}>{t("提现")}</li>
                <li id="tShared" onClick={tabClick} className={tabactive === 'tShared' ? "active" : ''}>{t("分享")}</li>
                <li id="tOutput" onClick={tabClick} className={tabactive === 'tOutput' ? "active" : ''}>{t("产出")}</li>
            </ul>
            <div className="record-list-box">
                <ul className="record-list-item record-list-title">
                    <li>{t("时间")}</li>

                    {tabactive == 'tExchange' && (<> <li>{t("支付数量")}</li>  <li>{t("获取数量")}</li></>)}
                    {tabactive == 'tWithdraw' && (<li>{t("提现金额")}</li>)}
                    {tabactive == 'tShared' && (<> <li>{t("地址")}</li>  <li style={{ textAlign: "right" }}>{t("收益")}</li></>)}

                    {tabactive != 'tShared' && tabactive != 'tOutput' && (<li style={{ textAlign: "right" }}>{t("状态")}</li>)}


                    {tabactive == 'tOutput' && (<li>{t("收益")}</li>)}
                    {tabactive == 'tOutput' && (<li style={{ textAlign: "right" }}>{t("收益率")}</li>)}
                    {/* {tabactive=='tShared' && (<li>{t("收益率")}</li>)} */}
                </ul>
                {tabactive == 'tExchange' && recordList && recordList.length > 0 && (recordList.map((item) => (
                    <ul className="record-list-item">
                        <li>{getTime(item.createdAt)}</li>
                        <li>{item.exchange_amount_from}<br />ETH</li>
                        <li>{item.exchange_amount_to} <br />USDT</li>
                        <li>{t("成功")}</li>
                    </ul>
                )))}
                {tabactive == 'tWithdraw' && recordList && recordList.length > 0 && (recordList.map((item) => (
                    <ul className="record-list-item">
                        <li>{getTime(item.createdAt)}</li>
                        <li>{item.withdraw_amount}<br />USDT</li>
                        {/* <li>{item.exchange_amount_to} <br/>USDT</li> */}
                        <li style={{ textAlign: "right" }}>{t(['已提交', '已确认', '已打款', '已拒绝'][item.withdraw_state])}</li>
                    </ul>
                )))}

                {tabactive == 'tShared' && recordList && recordList.length > 0 && (recordList.map((item) => (
                    <ul className="record-list-item">
                        <li>{getTime(item.createdAt)}</li>
                        <li>{hideEthAddress(item.income_form)}</li>
                        {/* <li>{item.exchange_amount_to} <br/>USDT</li> */}
                        <li style={{ textAlign: "right" }}>{item.income_amount_coin}</li>
                    </ul>
                )))}

                {tabactive == 'tOutput' && recordList && recordList.length > 0 && (recordList.map((item) => (
                    <ul className="record-list-item">
                        <li>{getTime(item.createdAt)}</li>
                        <li>{item.income_amount_coin} ETH</li>
                        {/* <li>{item.exchange_amount_to} <br/>USDT</li> */}
                        <li style={{ textAlign: "right" }}>{item.income_amount_coin}</li>
                    </ul>
                )))}


            </div>
        </div>
    )
}
export default RecordTab;
