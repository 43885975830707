import { Modal } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';

type SymbolPropsData = {
    showModal: boolean;
    closeLangModal: () => void,
    accept: () => void,
    regularData: any
};
const Regular1Modal: FC<SymbolPropsData> = (props: SymbolPropsData) => {
    const handleCancel = () => {
        props.closeLangModal()
    }
    const accept = () => {
        props.accept()
    }
    const { t } = useTranslation();
    const regularData = props.regularData;

    // const myVar: (date?: Parameters<typeof dayjs>[0]) => Dayjs = dayjs;

    // 时间戳转换
    const getTime = (time: any) => {
        return dayjs(Number(time) * 1000).format('YYYY-MM-DD HH:mm:ss');

    }
  



    return (<>{props.showModal ? <div className="lang-modal">
        <Modal title="" visible={props.showModal} closable={false} onCancel={handleCancel} footer={null}>
            <div className="lang-content" style={{ padding: "0px 0px 20px 0px" }}>
                <div className="regular-head">
                    <img src="image/eth_icon.png" />
                    <div>
                        <div className="title" style={{ fontSize: "15px" }}>{t("申请矿池奖励")}</div>
                        <div className="content" style={{ fontSize: "13px", color: "#FFFFFF99" }}>{t("ERC-20 Smart Contract")}</div>
                    </div>
                </div>
                <div className="caption-content">
                    {/* Toggle the tokens that need to be used to use mining */}
                    {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div className="left-text">{t("创建时间")}</div>
                        <div className="right-text">{getTime(regularData?.createdAt)}</div>
                    </div> */}
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "12px" }}>
                        <div className="left-text">{t("定存金额")}</div>
                        <div className="right-text">{regularData?.package_amount} USDT</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "12px" }}>
                        <div className="left-text">{t("定存天数")}</div>
                        <div className="right-text">{regularData?.package_days} {t("天")}</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "12px" }}>
                        <div className="left-text">{t("定存利率")}</div>
                        <div className="right-text">{regularData?.package_rate * 100} %</div>
                    </div>
                </div>
                <div className="regular_line"></div>
                <div className="symbolBtn" onClick={accept} style={{ height: "45px", lineHeight: "45px", fontWeight: "700" }}>{t("定存1ETH", { amount: regularData?.package_amount, symbol: "USDT" })}</div>
                {/* <div style={{ padding: '0 20px' }}>
                    <div style={{ display: "flex", alignItems: "center", marginTop: "12px" }}>
                    <img src="image/wallet-add.png" style={{height:"20px",marginRight:"6px"}}/>
                        <div className="right-text" style={{fontWeight:"400"}}>Standard: 51236.21 USDT</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginTop: "12px",marginBottom:"" }}>
                        <img src="image/empty-wallet-tick.png" style={{height:"20px",marginRight:"6px"}}/>
                        <div className="right-text" style={{fontWeight:"400"}}>add pool liquidity</div>
                    </div>
                </div> */}
            </div>
            {/* <div className="close-icon" onClick={handleCancel}>
                <img src="image/close.svg" />
            </div> */}
        </Modal>
    </div> : ""}</>)
}
export default Regular1Modal;