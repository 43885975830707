import { Modal } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

type SymbolPropsData = {
    showModal: boolean;
    closeLangModal: () => void,
    symbolSubmit:(symbol:any)=>void,
    symbolList: Array<any>,
    symbol: string
};
const Symbol: FC<SymbolPropsData> = (props: SymbolPropsData) => {

    const { t } = useTranslation();

    const handleCancel = () => {
        props.closeLangModal()
    }
   const [symbol , setSymbol ] = useState(props.symbol);
    // 确认选择币种
    const setSubmit =(symbol:any)=>{
        props.symbolSubmit(symbol)

    }
    const symbolList = props.symbolList;
    return (<>{props.showModal ? <div className="lang-modal">
        <Modal title="" visible={props.showModal} closable={false} onCancel={handleCancel} footer={null}>
            <div className="lang-content">
                <h1 className="captioon-head">{t('切换货币')}</h1>
                <div className="caption-content">
                    {t("切换需要用于挖矿的代币")}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>

                    <div className="symbol">
                        {symbolList.length > 0 && symbolList.map((item) => (<div onClick={()=>setSymbol(item.symbol)} className={(symbol == item.symbol && 'symbolSet') + ' symbol_btn'} style={{ display: "flex", alignItems: "center", gap: 6 }}>
                            <img src={item.icon} style={{ width: "18px", height: "18px" }} />
                            <div className="">{item.symbol}</div>
                        </div>))}


                    </div>
                </div>
                <div className="symbolBtn" onClick={()=>setSubmit(symbol)}>{t("确定")}</div>
            </div>
            <div className="close-icon" onClick={handleCancel}>
                <img src="image/close.svg" />
            </div>
        </Modal>
    </div> : ""}</>)
}
export default Symbol;