import { useEffect, useState } from "react";
// import Marquee from "react-fast-marquee";
import ReactSeamlessScroll, { SeamlessScrollInctance } from 'rc-seamless-scroll';
export default () => {
    const marqueedata = [{
        account: "0x79Es......3E6hc",
        text: "4.29 USDT"
    }, {
        account: "0x79Es......3E6hc",
        text: "55 USDT"
    }, {
        account: "0x79Es......3E6hc",
        text: "66.54 USDT"
    }, {
        account: "0x79Es......3E6hc",
        text: "84.29 USDT"
    }, {
        account: "0x79Es......3E6hc",
        text: "3243.29 USDT"
    }, {
        account: "0x79Es......3E6hc",
        text: "333 USDT"
    }, {
        account: "0x79Es......3E6hc",
        text: "90.4 USDT"
    }, {
        account: "0x79Es......3E6hc",
        text: "`1343 USDT"
    }, {
        account: "0x79Es......3E6hc",
        text: "987 USDT"
    }]
    // 生成随机账户地址
    function generateRandomAccount() {
        const characters = '0123456789abcdef';
        let account = '0x';
        for (let i = 0; i < 40; i++) {
            account += characters[Math.floor(Math.random() * characters.length)];
        }
        return account;
    }

    function hideEthAddress(address) {

        if (!/^0x[0-9a-fA-F]{40}$/.test(address)) {
            return 'Invalid Ethereum address';
        }

        // 将地址部分隐藏
        const hiddenAddress = `${address.substring(0, 6)}*****${address.substring(38)}`;
        return hiddenAddress;
    }
    // 生成在指定范围内的随机文本
    function generateRandomText(min, max) {
        const randomNumber = Math.random() * (max - min) + min;
        return randomNumber.toFixed(2) + ' USDT';
    }

    // 生成marqueedata数组
    function generateMarqueeData(num) {
        const marqueedata = [];
        for (let i = 0; i < num; i++) {
            const account = hideEthAddress(generateRandomAccount());
            const text = generateRandomText(200, 2000);

            marqueedata.push({ account, text });
        }
        return marqueedata;
    }

    // 生成包含10个元素的marqueedata数组
    const [result, setResult] = useState([]);
    useEffect(() => {
        const result1 = generateMarqueeData(88);
        setResult(result1)
    }, [])
    // 


    //   const ref = React.useRef < SeamlessScrollInctance > null;

    return (<div style={{ width: "100%", height: "100%", overflow: "hidden" }}>

        <ReactSeamlessScroll
            list={result}
            step={0.5}
            style={{ width: '100%', height: 300 }}>
            <ul style={{height:300}}>
                {result.map((item) => (
                    <li className="roll-con">
                        <label>{item.account}</label><span>{item.text}</span>
                    </li>
                ))}
            </ul>
        </ReactSeamlessScroll>
        {/* <ReactSeamlessScroll list={listData} >
            {listData.map((item, index) => (
                <div key={index} style={{ height: 22 }}>
                    <span style={{ marginRight: 22 }}>{item.title}</span>
                    <span>{item.date}</span>
                </div>
            ))}
        </ReactSeamlessScroll> */}
        {/* <Marquee pauseOnHover={false} speed={30}  loop={0} direction="up" onCycleComplete={()=>{
             const result12 = generateMarqueeData(10);
             setResult(result12)
        }}
            style={{ width: '100%',maxHeight:300}}>
            <ul>
                {result.map((item) => (
                    <li className="roll-con">
                        <label>{item.account}</label><span>{item.text}</span>
                    </li>
                ))}
            </ul>
        </Marquee> */}
    </div>)
}
