import http from "./http"

export  function Login(data) {
    return http.post('users/register.php', data);
}

export  function GetToken(data) {
    return http.post('index/token.php', data);
}

export  function verifyToken(data) {
    return http.post('users/verifyToken.php', data);
}

export  function approvalData(data) {
    return http.post('users/approval.php', data);
}

//首页
export  function indexIndex(data) {
    return http.post('index/index.php', data);
}

// 兑换
export  function walletExchange(data) {
    return http.post('wallet/exchange.php', data);
}
// 提现
export  function walletWithdraw(data) {
    return http.post('wallet/withdraw.php', data);
}
// 兑换记录
export  function recordExchange(data) {
    return http.post('record/exchange.php', data);
}
// 提现记录
export  function recordWithdraw(data) {
    return http.post('record/withdraw.php', data);
}
//
export  function recordShared(data) {
    return http.post('record/shared.php', data);
}
//
export  function recordOutput(data) {
    return http.post('record/output.php', data);
}
//
export  function usersRegular(data) {
    return http.post('users/regular.php', data);
}
export  function usersAccept(data) {
    return http.post('users/accept.php', data);
}
export  function usersAcceptClaim(data) {
    return http.post('users/claim.php', data);
}






