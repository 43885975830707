import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import {message} from "antd";
import { indexIndex,verifyToken } from '../api/httpApiList';

export  default ()=> {
    const history = useHistory();
    const {t} = useTranslation();
    // const [shareUrl, setShareUrl] = useState("");

    const [minData, setMinData] = useState({})
    const [homeData,setHomeData] = useState({});

    useEffect(()=>{
        indexIndex().then((res)=>{
            if(res.ok){
                setHomeData(res.data)
            }
        })
    },[]);

    useEffect(() => {
        verifyToken().then((res) => {
            if (res.ok) {
                setMinData(res.data)
            }
        })
    }, [])
    const copyHandle = () => {
        copy(homeData?.share_domain + '?id='+minData?.invite)
        message.success(t('复制成功'))
    }
    const back = () => {
        history.goBack()
    }
    return (
        <div className="share-wrapper">
            <div className="share-head">
                <div className="back" onClick={back}>
                    <img src="image/back.png"/>
                </div>
                <label>{t("分享给朋友")}</label>
            </div>
            <div className="share-content">
                <p className="title">{t("我的邀请链接")}</p>
                <div className="share-url">
                    <label>{homeData?.share_domain || ''}?id={minData?.invite || ""}</label>
                    <span className="btn" onClick={copyHandle}>{t("复制")}</span>
                </div>
            </div>
            <div className="share-block">
                <div className="s-con">
                {t("发送您的邀请链接，朋友通过您的链接加入节点，您将获得丰厚的代币奖励。")}
                </div>
                <div className="share-btn">{t("立即邀请")}</div>
                <img src="image/Illustration.png" className="share-pic"/>
            </div>
        </div>
    )
}
