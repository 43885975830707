import { combineReducers } from 'redux'

const initialState = {
    togglePledgeDetail: false,
    toggleHowToBuy: false
}
const global =  (state = initialState, action:any) => {
    switch (action.type) {
        case "setToggleHowToBuy":
            return {
                ...state,
                toggleHowToBuy: action.data
            }
        case "setTogglePledgeDetail":
            return {
                ...state,
                togglePledgeDetail: action.data
            }
         default:
            return state;
    }
}


export default combineReducers({
    global
})
