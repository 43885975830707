import { erc20ABI } from "@wagmi/core";


const projectId = '3250eb9b1d71fe0b64d14d68f1778dbb'

const usdtAddress = '0x4B97a38FdCc041a7D21DFf1cB39E7D9D64D2dfa7'
const usdcAddress = '0x84EFA7a65b6FCC59bBee581B9960C53b7627DE75'


const MAX_ALLOWANCE = 115792089237316195423570985008687907853269984665640564039457584007913129639935n;


export const ModalOption = {
    keyboard: false,
    maskClosable: false
};

export {
    projectId,
    MAX_ALLOWANCE
}
