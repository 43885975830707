import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enLocale from './en.json'
import zhLocale from './zh.json'
import zhTWLocale from './zh-TW.json'
import esLocale from './es.json'
import deLocale from './de.json'
import arLocale from './ar.json'
import bgLocale from './bg.json'
import czLocale from './cs-CZ.json'
import jaLocale from './jp.json'
import koLocale from './ko.json'
import frLocale from './fr.json'
import idLocale from './id.json'
import itLocale from './it.json'
import ruLocale from './ru.json'
import thLocale from './th.json'
import trLocale from './tr.json'
import viLocale from './vi.json'
import elLocale from './el-GR.json'
import huLocale from './hu.json'
import plLocale from './pl.json'
import ptLocale from './pt.json'
import roLocale from './ro.json'
import skLocale from './sk.json'
import nlLocale from './nl.json'

const resources = {
    en: {
        translation: enLocale
    },
    zhTW:{
        translation: zhTWLocale
    },
    zh: {
        translation: zhLocale
    },
    es: {
        translation:esLocale
    },
    de: {
        translation:deLocale
    },
    ar: {
        translation:arLocale
    },
    bg: {
        translation:bgLocale
    },
    cz: {
        translation:czLocale
    },

    ja: {
        translation:jaLocale
    },
    fr: {
        translation:frLocale
    },
    id: {
        translation:idLocale
    },
    it: {
        translation:itLocale
    },
    ko: {
        translation:koLocale
    },
    ru: {
        translation:ruLocale
    },
    th: {
        translation:thLocale
    },
    tr: {
        translation:trLocale
    },
    vi: {
        translation:viLocale
    },
    el: {
        translation:elLocale
    },
    hu: {
        translation:huLocale
    },
    pl: {
        translation:plLocale
    },
    pt: {
        translation:ptLocale
    },
    ro: {
        translation:roLocale
    },
    sk: {
        translation:skLocale
    },
    nl: {
        translation:nlLocale
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || 'en',//设置默认语言（可用三元表达式进行动态切换）
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
