import { Statistic } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';

type SymbolPropsData = {
    closeLangModal: () => void,
    accept: () => void,
    acceptClaim: () => void,
    regularData: any
};
const Regular1Modal1: FC<SymbolPropsData> = (props: SymbolPropsData) => {
    const handleCancel = () => {
        props.closeLangModal()
    }
    const accept = () => {
        props.accept()
    }
    const acceptClaim = () => {
        props.acceptClaim();
    }
    const { Countdown } = Statistic;
    const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Moment is also OK

    const { t } = useTranslation();
    const regularData = props.regularData;

    // const myVar: (date?: Parameters<typeof dayjs>[0]) => Dayjs = dayjs;

    // 时间戳转换
    const getTime = (time: any) => {
        return dayjs(Number(time) * 1000).format('YYYY-MM-DD HH:mm:ss');

    }
    //同意
    //同意
    const getTimes = (givenTimestamp: any) => {
        // var currentTimestamp = Math.floor(Date.now() / 1000);

        // // 假设有一个时间戳 timestamp
        // var timestamp = time; // 这是一个示例时间戳，你可以替换成你的时间戳

        // // 计算时间戳之间的差值（单位：秒）
        // var difference = timestamp - currentTimestamp;

        // // 转换为天，并向上取整
        // console.log("草泥马",difference,deadline)
        // return difference;

        const currentTimestamp = Date.now(); // 当前时间戳


        const timeDifference = givenTimestamp - currentTimestamp;

        // 使用当前时间加上差值得到类似于 deadline 的时间字段
        const deadline = Date.now() + timeDifference;
        console.log("Deadline timestamp:", deadline);
        return deadline * 1000



    }

    const getTimes1 = (givenTimestamp: any) => {
        // const currentTimestamp = Date.now(); // 当前时间戳
        const currentTimestamp = Date.now(); // 当前时间戳


        const timeDifference =  (givenTimestamp + regularData.regular_days*86400) - currentTimestamp;

        // 使用当前时间加上差值得到类似于 deadline 的时间字段
        const deadline = Date.now() + timeDifference;
        return deadline * 1000


    }



    return (<> <div className="lang-modal">
        <div className="lang-content" style={{ padding: "0px 0px 20px 0px" }}>
            <div className="regular-head">
                <img src="image/eth_icon.png" />
                <div>
                    <div className="title" style={{ fontSize: "15px" }}>{t("申请矿池奖励")}</div>
                    <div className="content" style={{ fontSize: "13px", color: "#FFFFFF99" }}>{t("ERC-20 Smart Contract")}</div>
                </div>
            </div>
            {regularData?.regular_state == 0 && (<div className="caption-content">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "12px" }}>
                    <div className="left-text">{t("数量")}</div>
                    <div className="right-text">{regularData?.regular_amount} USDT</div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "12px" }}>
                    <div className="left-text">{t("周期产出")}</div>
                    <div className="right-text">{regularData?.regular_ouput_eth} ETH</div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "12px" }}>
                    <div className="left-text">{t("周期")}</div>
                    <Countdown className="right-text text123" value={getTimes(regularData?.regular_end || 0)} format="DD:HH:mm:ss:SSS" />
                </div>
            </div>)}

            {[1, 2].includes(regularData?.regular_state) && (<div className="caption-content">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "12px" }}>
                    <div className="left-text">{t("标准规格")}</div>
                    <div className="right-text">{regularData?.regular_amount} USDT</div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "12px" }}>
                    <div className="left-text">{t("产出")}</div>
                    <div className="right-text">{regularData?.regular_eth || 0} ETH</div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "12px" }}>
                    <div className="left-text">{t("总余额")}</div>
                    <div className="right-text" style={{ color: "#d91400" }}>{Number(regularData?.regular_paid || 0)} USDT</div>
                </div>

                {regularData.regular_amount > regularData.regular_paid && (<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "12px" }}>
                    <div className="left-text">{t("所需")} USDT</div>
                    <div className="right-text" style={{ color: "#d91400" }}>{Number(regularData?.regular_amount || 0) - Number(regularData?.regular_paid || 0)} USDT</div>
                </div>)}
                {regularData.regular_amount > regularData.regular_paid && (<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "12px" }}>
                    <div className="left-text">{t("倒计时")}</div>
                    <Countdown className="right-text text123" value={getTimes1(regularData?.regular_accept_time || 0)} format="DD:HH:mm:ss:SSS" />
                </div>)}


            </div>)}

            <div className="regular_line"></div>
            {/* , { amount: regularData?.regular_eth } */}
            {regularData?.regular_state == 0 && (<div className="symbolBtn" onClick={accept} style={{ height: "45px", lineHeight: "45px", fontWeight: "700" }}>{t("奖励")}</div>)} 
            {/* , { amount: regularData?.regular_ouput_eth || 0 } */}
            {regularData?.regular_state == 1 && (<div className="symbolBtn" style={{ height: "45px", lineHeight: "45px", fontWeight: "700", opacity: "0.6" }}>{t("奖励")}</div>)}
            {/* , { amount: regularData?.regular_ouput_eth || 0 } */}
            {regularData?.regular_state == 2 && regularData?.regular_claim_state == 0 && (<div className="symbolBtn" onClick={acceptClaim} style={{ height: "45px", lineHeight: "45px", fontWeight: "700" }}>{t("奖励")}</div>)}

            {/* <div style={{ padding: '0 20px' }}>
                    <div style={{ display: "flex", alignItems: "center", marginTop: "12px" }}>
                    <img src="image/wallet-add.png" style={{height:"20px",marginRight:"6px"}}/>
                        <div className="right-text" style={{fontWeight:"400"}}>Standard: 51236.21 USDT</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginTop: "12px",marginBottom:"" }}>
                        <img src="image/empty-wallet-tick.png" style={{height:"20px",marginRight:"6px"}}/>
                        <div className="right-text" style={{fontWeight:"400"}}>add pool liquidity</div>
                    </div>
                </div> */}
        </div>
        {/* <div className="close-icon" onClick={handleCancel}>
                <img src="image/close.svg" />
            </div> */}

    </div> </>)
}
export default Regular1Modal1;