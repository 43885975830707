import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import './assets/css/reset.css';
import './assets/css/style.scss';
import Index from "./view/Index";
import Share from "./view/Share";
import PoolData from './view/PoolData'
import MyAccount from "./view/MyAccount";

import "./lang/i18n";

const App = () => {
    return (
        <Router>
            <CacheSwitch>
                <CacheRoute exact path="/" render={() =>  <Index /> } />
                <Route exact path="/share" render={() => <Share /> } />
                <Route exact path="/poolData" render={() => <PoolData /> } />
                <Route exact path="/myAccount" render={() => <MyAccount /> } />
            </CacheSwitch>
        </Router>
    );
};

export default App;
