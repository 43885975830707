import { createRoot } from 'react-dom/client';
import { Spin } from 'antd';

export const showGlobalLoading = () => {
    const dom = document.createElement('div');
    dom.id = 'globalLoading';
    dom.style.cssText = 'z-index:99999; width: 100%; height: 100%; position: fixed; top: 0; left: 0; background-color: rgba(255, 255, 255, 0.45); display: flex; justify-content: center; align-items: center; z-index: 9999;';
    document.body.appendChild(dom);

    const root = createRoot(dom);
    root.render(<Spin  />);
}

export const hideGlobalLoading = () => {
    const dom = document.getElementById('globalLoading');
    if (dom) {
        const root = createRoot(dom);
        root.unmount();
        document.body.removeChild(dom);
    }
}