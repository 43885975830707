import {Modal} from "antd";
import {FC} from "react";
type ChildrenPropsData = {
    showModal: boolean;
    closeLangModal: () => void
};
const Caption:FC<ChildrenPropsData> = (props:ChildrenPropsData) => {
    const handleCancel = () => {
        props.closeLangModal()
    }
    return(<>{props.showModal ?<div className="lang-modal">
        <Modal title="" visible={props.showModal} closable={false} onCancel={handleCancel} footer={null}>
            <div className="lang-content">
                <h1 className="captioon-head">Caption</h1>
                <div className="caption-content">
                    In modern times, a philosopher is an intellectual who contributes to one or more branches of philosophy, including aesthetics, ethics, theory of knowledge, philosophy of science, logic, metaphysics, social theory, philosophy of religion, and political philosophy.
                </div>
                <div className="catption-img">
                    <img src="image/Rectangle.png"/>
                </div>
            </div>
            <div className="close-icon" onClick={handleCancel}>
                <img src="image/close.svg"/>
            </div>
        </Modal>
    </div>: ""}</>)
}
export default Caption;
