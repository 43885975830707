import {FC} from "react";
import { Modal } from 'antd';

type ChildrenPropsData = {
    showModal: boolean;
    closeLangModal: () => void
};
const ExchangeModal:FC<ChildrenPropsData> = (props:ChildrenPropsData) =>  {
    const handleCancel = () => {
        props.closeLangModal()
    }
    const exchangeHandle = () => {}
    return(<>
        {props.showModal ? <div className="lang-modal">
            <Modal title="" visible={props.showModal} closable={false} onCancel={handleCancel} footer={null}>
                <div className="lang-content">
                    <h1>Chain Coin Selection</h1>
                    <div className="exchange-content">
                        <p className="exchange-title">Select your chain</p>
                        <div className="exchange-list">
                            <span className="exchange-item active">ETH</span>
                            <span className="exchange-item">BFC</span>
                        </div>
                        <p className="exchange-title">Select your currency</p>
                        <div className="exchange-list">
                            <span className="exchange-item">USDT</span>
                            <span className="exchange-item">USDC</span>
                        </div>
                        <div className="exchange-btn" onClick={exchangeHandle}>Exchange</div>
                    </div>
                </div>
            </Modal>
        </div>:""}
    </>)
}
export default ExchangeModal;
