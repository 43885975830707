import {FC} from "react";
import { Modal } from 'antd';

type ChildrenPropsData = {
    showModal: boolean;
    closeModal: () => void
};
const RewardsModal:FC<ChildrenPropsData> = (props:ChildrenPropsData) => {
    const handleCancel = () => {
        props.closeModal()
    }
    return (<>
        {props.showModal ? <div className="lang-modal">
            <Modal title="" visible={props.showModal} closable={false} onCancel={handleCancel} footer={null}>
                <div className="lang-content">
                    <div className="rewards-head">
                        <img src="image/icon.png"/>
                        <div className="rewards-head-rt">
                            <p>Apply Mining Pool Rewards</p>
                            <p>ERC-20 Smart Contract</p>
                        </div>
                    </div>
                    <div className="rewards-content">
                        <ul>
                            <li><label>Liquidity</label><span>65000 USDT</span></li>
                            <li><label>Cycle Output</label><span>5.65671132 ETH</span></li>
                            <li><label>Contract period</label><span>7 Day</span></li>
                        </ul>
                        <div className="btn">Reward 3 ETH</div>
                        <div className="rewards-bot"><img src="image/empty-wallet-tick.png"/>Standard: 51236.21 USDT</div>
                        <div className="rewards-bot"><img src="image/wallet-add.png"/>add pool liquidity
                        </div>
                    </div>
                </div>
                <div className="close-icon" onClick={handleCancel}>
                    <img src="image/close.svg"/>
                </div>
            </Modal>
        </div>:""}
    </>)
}
export default RewardsModal;
