import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "./lang/en.json";
import ar from "./lang/ar.json";
import zh_CN from "./lang/zh-CN.json";
import zh_HK from "./lang/zh-HK.json";
import de from "./lang/de.json";
import es from "./lang/es.json";
import fr from "./lang/fr.json";
import id from "./lang/id.json";
import it from "./lang/it.json";
import ja from "./lang/ja.json";
import ko from "./lang/ko.json";
import pl from "./lang/pl.json";
import pt from "./lang/pt.json";
import ru from "./lang/ru.json";
import th from "./lang/th.json";
import vi from "./lang/vi.json";


i18n
    // 注入 react-i18next 实例
    .use(initReactI18next)
    // 初始化 i18next
    .init({
        debug: false,
        fallbackLng: 'en',
        lng:localStorage.getItem("lang")|| 'en', // 默认语言
        interpolation: {
            escapeValue: false,
        },


        resources: {
            "en": {
                translation: en
            },
            "ar": {
                translation: ar
            },
            // "zh-CN": {
            //     translation: zh_CN
            // },
            // "zh-HK": {
            //     translation: zh_HK
            // },
            "de": {
                translation: de
            },
            "fr": {
                translation: fr
            },
            "id": {
                translation: id
            },
            "ja": {
                translation: ja
            },
            "ko": {
                translation: ko
            },
            "pl": {
                translation: pl
            },
            "pt": {
                translation: pt
            },
            "ru": {
                translation: ru
            },
            "th": {
                translation: th
            },
            "it": {
                translation: it
            },
            "es": {
                translation: es
            },
            "vi": {
                translation: vi
            },
            

        }
    });
export default i18n;
